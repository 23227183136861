import bookingService from '../../../services/booking.service';
import Service from '../../../models/Service';
import ServiceState from './service.state';

export default {

  state: {
    list: [],
    selected: {},
  } as ServiceState,

  getters: {
    multipleServicesAvailable: (
      state: ServiceState,
    ) => state.list.length > 1
  },

  mutations: {
    setList: (
      state: ServiceState,
      services: Array<Service>,
    ) => {
      state.list = services;
    },

    setSelected: (
      state: ServiceState,
      service: Service,
    ) => {
      state.selected = service;
    },
  },

  actions: {
    async fetchServices({ commit , rootState }: any) {
      const payload = {
        customer: rootState.user,
        animal: rootState.animal.selected,
      };

      return bookingService.fetchServices(payload)
        .then((services: Array<Service>) => {
          commit('setList', services);

          if (services.length < 2) {
            const [ service ] = services;
            commit('setSelected', service);
          }
        }).catch(console.error);
    },
  },
}