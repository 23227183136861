






import Vue from 'vue';
import BaseSpinner from '../misc/BaseSpinner.vue';

export default Vue.extend({
  name: 'LoadingSpinner',
  components: {
    BaseSpinner,
  },
})
