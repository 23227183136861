








































import Vue from 'vue';
import Avatar from '../misc/Avatar.vue';
import Badge from '../misc/Badge.vue';

export default Vue.extend({
  components: {
    Avatar,
    Badge,
  },
  props: {
    availableSlot: {
      required: true,
      type: Object,
    },
    badgeText: {
      type: String,
      default: '',
    }
  },
  computed: {
    priceString(): string {
      return this.availableSlot.price.amount > 0 ? this.availableSlot.price.amount + this.availableSlot.price.currency : this.$t('free');
    },
  },
})
