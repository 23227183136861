import Vue, { VueConstructor } from 'vue';
import { mapGetters } from 'vuex';
import BOOKING_ROUTES from './booking-routes';

export default Vue.extend({
  data() {
    return {
      currentRoute: JSON.parse(localStorage.getItem('fv-current-route')!) || '' as string,
      history: JSON.parse(localStorage.getItem('fv-route-history')!) || [] as Array<string>,
    };
  },
  computed: {
    ...mapGetters('animal', ['hasMultipleAnimals']),
    ...mapGetters('service', ['multipleServicesAvailable']),
    initialView(): string {
      if (this.currentRoute.length) {
        return this.currentRoute;
      } if (this.hasMultipleAnimals) {
        return 'select-animal';
      }

      if (this.multipleServicesAvailable) {
        return 'select-service' ;
      }

      return 'select-slot';
    },
    currentComponent(): VueConstructor<Vue> {
      return BOOKING_ROUTES[this.currentRoute];
    },
    hasHistory(): boolean {
      return this.history.length > 1;
    },
  },
  watch: {
    $data: {
      handler: (routeState) => {
        localStorage.setItem('fv-current-route', JSON.stringify(routeState.currentRoute));
        localStorage.setItem('fv-route-history', JSON.stringify(routeState.history));
      },
      deep: true
    }
  },
  methods: {
    setInitalView(): void {
      this.currentRoute = this.initialView;
    },
    backspace(index = 1): void {
      if (this.hasHistory && this.canBack(index)) {
        this.history = this.history.slice(0, -Math.abs(index));
        this.currentRoute = this.history[this.history.length - 1];
      }
    },
    navigateTo(event: { name: string; clearHistory: boolean }): void {
      const { name, clearHistory } = event;
      if (clearHistory) {
        this.history = [];
      }
      if (!this.history.includes(name)) {
        this.history.push(name);
      }
      this.currentRoute = name;
    },
    canBack(index: number): boolean {
      return index < this.history.length;
    },
    resetRouter(): void {
      this.currentRoute = '';
      this.history = [];
    },
  },
})
