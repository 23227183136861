

























import Vue from 'vue'
import { mapMutations, mapState, mapActions } from 'vuex';
import ListItemNavigation from '@/components/list-items/ListItemNavigation.vue';
import ListItemDivider from '@/components/list-items/ListItemDivider.vue';
import PageHeader from '@/components/modal/PageHeader.vue';
import Service from '../../models/Service';

export default Vue.extend({
  components: {
    PageHeader,
    ListItemNavigation,
    ListItemDivider,
  },
  computed: {
    ...mapState('service', { serviceList: 'list' }),
    ...mapState('animal', { selectedAnimal: 'selected' }),
  },
  mounted() {
    if (!this.serviceList.length) {
      this.fetchServiceList();
    }
  },
  methods: {
    ...mapActions('service', ['fetchServices']),
    ...mapActions('overlay', ['showLoadingSpinner']),
    ...mapMutations('calendar', ['clearState']),
    ...mapMutations('service', ['setSelected']),

    async fetchServiceList(): Promise<void> {
      this.showLoadingSpinner(true);
      await this.fetchServices();
      this.showLoadingSpinner(false);
    },
    selectService(service: Service): void {
      this.setSelected(service);
      this.$trackingEvents.clickedSelectServiceWidget(service);

      this.clearState();

      this.$emit('navigate', { name: 'select-slot' });
    }
  },
})
