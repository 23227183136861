


















import Vue from 'vue';
import { mapMutations } from 'vuex';
import InlineLink from './InlineLink.vue';

export default Vue.extend({
  name: 'TosInformationText',
  components: {
    InlineLink,
  },
  methods: {
    ...mapMutations('overlay', { openOverlay: 'open', closeOverlay: 'close' }),
    openTerms(): void {
      this.$trackingEvents.clickedViewTosWidget();
      this.openOverlay({
        type: 'tos',
        onClose: () => {
          this.closeOverlay();
        },
      });
    },
    openPrivacyPolicy(): void {
      this.$trackingEvents.clickedViewPrivacyPolicyWidget();
      this.openOverlay({
        type: 'privacy-policy',
        onClose: () => {
          this.closeOverlay();
        },
      });
    },
    openCookies(): void {
      this.$trackingEvents.clickedViewCookiesWidget();
      this.openOverlay({
        type: 'cookies',
        onClose: () => {
          this.closeOverlay();
        },
      });
    },
  }
})
