







































import Vue from 'vue';
import { mapState, mapActions, mapMutations } from 'vuex';
import BookingContainer from '@/components/containers/BookingContainer.vue';
import ListItemAction from '@/components/list-items/ListItemAction.vue';
import ListItemInputPhone from '@/components/list-items/ListItemInputPhone.vue';
import ProvidedInformation from '@/components/misc/ProvidedInformation.vue';
import PageHeader from '@/components/modal/PageHeader.vue';

export default Vue.extend({
  components: {
    BookingContainer,
    PageHeader,
    ProvidedInformation,
    ListItemInputPhone,
    ListItemAction
  },
  computed: {
    ...mapState('auth', ['countryCodes']),
    ...mapState('user', { userPhone: 'phone' }),
  },
  mounted() {
    (this.$refs.phoneNumber as any).$refs.input.focus();
  },
  methods: {
    ...mapActions('auth', ['validatePhone']),
    ...mapMutations('user', ['setPhone']),
    async submit(): Promise<void> {
      const isValid = await this.$validator.validate();
      if (isValid) {
        this.$emit('navigate', { name: 'confirm-details' });
      }
    },
  },
})
