


















import Vue from 'vue';
import FirstvetLogo from '../misc/FirstvetLogo.vue';

export default Vue.extend({
  name: 'WidgetModalHeader',
  components: {
    FirstvetLogo,
  },
  props: {
    stylingClass: {
      type: String,
      default: '',
    },
    hasHistory: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    logoColor(): string {
      return this.stylingClass === 'firstvet-blue' ? 'white' : 'default';
    },
    backButtonClasses(): string {
      if (this.logoColor === 'white') {
        return 'white';
      }

      return !this.hasHistory ? 'hidden' : '';
    }
  }
})
