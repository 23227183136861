







import Vue from 'vue';
import BaseSpinner from '../misc/BaseSpinner.vue';

export default Vue.extend({
  name: 'ActivitySpinner',
  components: {
    BaseSpinner,
  },
  props: {
    color: {
      type: String,
      default: 'white',
    },
  },
})
