export default {
  NEXT_AVAILABLE_SLOT_INTERVAL: (60 * 1000),
  AMOUNT_OF_CALENDAR_DATES: 7,
  VET_SERVICE_ID: 1,
  PIN_CODE_LENGTH: 4,
  COUNTRY_IDS: {
    'uk': 826,
    'gb': 826,
    'se': 752,
    'us': 840,
    'de': 276,
  } as Record<string, number>,
  COUNTRY_LOCALES: {
    uk: 'en-GB',
    gb: 'en-GB',
    se: 'sv',
    us: 'en-GB',
    de: 'de',
  }  as Record<string, string>,
  FIREBASE: {
    API_KEY: process.env.VUE_APP_FIREBASE_API_KEY,
    AUTH_DOMAIN: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    PROJECT_ID: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    STORAGE_BUCKET: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    MESSAGING_SENDER_ID: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    APP_ID: process.env.VUE_APP_FIREBASE_APP_ID,
    MEASUREMENT_ID: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
  },
  VUEX_PERSIST_KEY: 'fv-vuex-store',
  SHOW_PRICE: 0,
  PREVENT_BOOKING_VALIDATION_ROUTES: [
    'select-animal',
    'select-slot',
  ],
}
