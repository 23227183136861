import Vue from 'vue';
import VueI18n from 'vue-i18n';
import config from '../config';
import deTranslations from './lang/de';
import svTranslations from './lang/sv';
import ukTranslations from './lang/uk';

Vue.use(VueI18n);

const messages = {
  'de': Object.assign(deTranslations),
  'sv': Object.assign(svTranslations),
  'en-GB': Object.assign(ukTranslations),
};

const locale = config.COUNTRY_LOCALES.uk;

export default new VueI18n({
  locale,
  fallbackLocale: locale,
  messages,
});
