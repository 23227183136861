import Vet from './booking/Slot/Vet';

export default class NextAvailableSlot {

  constructor(
    public id: number,
    public date: string,
    public vet: Vet,
  ) { }

  static formatHttpResponse(response: any): NextAvailableSlot {
    return new this(
      response.id,
      response.dateTime,
      Vet.formatHttpResponse(response.veterinary || {}),
    );
  }
}
