




















import Vue from 'vue';

export default Vue.extend({
  name: 'PageHeader',
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    additionalClasses: {
      type: String,
      default: '',
    },
  },
})
