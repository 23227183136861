














import Vue from 'vue';
import DownloadAppBadge from './DownloadAppBadge.vue';

export default Vue.extend({
  name: 'DownloadAppBadges',
  components: {
    DownloadAppBadge,
  },
})
