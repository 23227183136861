import Vue from 'vue';
import Animal from '../models/Animal';
import BookingState from '../store/modules/booking/booking.state';
import NextAvailableSlot from '../models/NextAvailableSlot';
import User from '../interfaces/User';
import WidgetCustomEvents from '../interfaces/WidgetCustomEvents';

function dispatch(event: CustomEvent): void {
  window.dispatchEvent(event);
}

function createEvent(eventName: string, eventData: object = {}): CustomEvent {
  return new CustomEvent(eventName, {
    detail: eventData,
  });
}

const customEvents: WidgetCustomEvents = {
  onModalOpen: () => {
    dispatch(createEvent('fv-on-modal-open'));
  },
  onModalClose: () => {
    dispatch(createEvent('fv-on-modal-close'));
  },
  onFetchedNextAvailableSlot: (nextAvailableSlot: NextAvailableSlot) => {
    dispatch(createEvent('fv-on-fetched-next-available-slot', nextAvailableSlot));
  },
  onBookingComplete: (
    animal: Animal,
    user: User,
    booking: BookingState,
  ) => {
    dispatch(createEvent('fv-on-booking-complete', {
      animal,
      user: {
        email: user.email,
        phone: user.phone,
      },
      booking: {
        appointmentId: booking.appointmentId,
        date: booking.slot!.dateInfo.date,
        time: booking.slot!.dateInfo.time,
        vet: booking.slot!.vet.displayName,
        service: booking.slot!.serviceInfo.name,
      },
    }));
  },
}

Vue.prototype.$customEvents = customEvents;
export default customEvents;