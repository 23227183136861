/* eslint-disable @typescript-eslint/camelcase */
const svTranslation: object = {
  next_available_time: 'Nästa lediga tid',
  next: 'Nästa',
  with: 'med',
  select_your_pet: 'Välj djur',
  select_pet_description: 'Det är detta djuret som konsultationen kommer fokusera på.',
  select_service: 'Välj tjänst',
  select_service_description: 'Välj en tjänst nedan för att påbörja din bokning.',
  select_slot_title: 'Välj tid',
  select_slot_description: 'Välj en tid för ditt möte med en av våra veterinärer. Mötet genomförs i vår app.',
  describe_symptom_title: 'Beskriv ditt ärende',
  describe_symptoms_description: 'Skriv en kort beskrivning av det du behöver hjälp med och ladda upp bilder som kan vara till hjälp för veterinären.',
  describe_symptoms_placeholder: 'Beskriv symptom, beteenden eller liknande..',
  continue: 'Fortsätt',
  add_images: 'Lägg till max 6 bilder',
  amount_of_images: '{amount} bild | {amount} bilder',
  confirm_phone_number: 'Vi har skickat ett SMS med en {count}-siffrig kod. Fyll i koden för att avsluta bokningen.',
  wrong_pin: 'Fel kod',
  please_try_again: 'Försök igen',
  go_back_to_update_your_phone_number: 'Gå tillbaka för att uppdatera ditt telefonnummer',
  resend_pin_code: 'Skicka ny kod',
  almost_there: 'Nästan klar',
  confirm_details_description: 'Acceptera Firstvets användarvillkor för att fortsätta till sista steget.',
  continue_to_final: 'Fortsätt till sista steget',
  phone_number: 'Telefonnummer',
  time_of_appointment: 'Mötestid',
  email: 'Email',
  pet: 'Djur',
  terms_of_use: 'Användarvillkor',
  privacy_policy: 'Integritetspolicy',
  cookie_policy: 'Cookie Policy',
  agree_terms: 'Jag godkänner med Firstvet',
  confirm_read_understood: 'och medger att jag har läst och förstått',
  done_title: 'Klart! Fortsätt med detta.',
  book_another_animal: 'Boka för ett annat djur',
  get_sms_reminder: 'Du kommer även få en påminnelse via SMS.',
  login_with_phone: 'Logga in med ditt telefonnummer',
  download_app: 'Ladda ner FirstVet appen',
  booking_complete_instruction_1: 'Mötet kommer genomföras i appen.',
  booking_complete_instruction_2: 'I appen — logga in med {phoneNumber}.',
  booking_complete_instruction_3: 'Du kommer se din bokning på startsidan.',
  book: 'Boka',
  minutes_short: 'min',
  tomorrow: 'Imorgon',
  today: 'Idag',
  no_available_times: 'Inga lediga tider',
  free: 'Gratis',
  jump_next_day: 'Hoppa till nästa dag',
  images_max_message: 'Max 6 bilder!',
  image_size_limitation: 'Filerna är för stora, totalt max 10mb!',
  invalid_file_type_message: 'Uppladdat bildformat är inte tillåtet!',
  add_phone_number_title: 'Lägg till ditt mobilnummer',
  add_phone_number_description: 'Det används för att konfirmera din bokning SMS och som identifikation när det är dags för din möte.',
  phone_placeholder: '70 555 55 55',
  something_went_wrong: 'Något gick fel',
  contact_our_support: 'Kontakta vår support på support@firstvet.com',
  i_understand: 'Jag förstår',
  slot_is_already_booked: 'Någon annan hann boka tiden före dig. Vänligen välj en annan tid.',
  slot_no_longer_available: 'Tiden är inte tillgänglig',
  slot_no_longer_available_desc: 'Din bokning tog för lång tid så tiden du valt är inte längre tillgänglig',
  select_a_new_slot: 'Välj en annan tid',
  sorry: 'Förlåt!',
  field_required_message: 'Fältet är obligatoriskt',
  by_continuing_you_agree: 'Genom att fortsätta godkänner du våra',
  and_confirm_you_have_read: 'och bekräftar att du har läst och förstått vår',
  including_use_of: 'samt användning av',
  cookies: 'Cookies',
  tos_text_de_ending: '',
  bad_user_credentials_title: 'Vi kunde inte skapa kontot',
  bad_user_creation_credentials: 'Vi kunde inte skapa kontot med denna kombination av telefonnummer och e-postadress.' +
    '<br/><br/>Har du tidigare använt FirstVet med ett annat telefonnummer? <a href="{loginUrl}">Försök med det numret</a> istället.' +
    '<br/><br/>Du kan även <a href="mailto:{supportEmail}">kontakta vår kundtjänst.</a>' +
    '<br/><br/>Felmeddelande: 1DER',
  support_email: 'support@firstvet.com',
  no_pin_code_received: 'Fick du inget SMS?',
  no_pin_code_download_app: 'Om du inte har fått någon PIN-kod, ladda ner appen för att boka.' +
  '<br/>I appen loggar du in med BankID.' +
  '<br/><br/><a href="{appUrl}" target="_blank" class="download-app">Ladda ner FirstVet-appen</a>' + 
  '<br/><br/>Om du har fått PIN-koden, stäng det här meddelandet för att ange koden.',
  app_url: 'https://firstvet.page.link/app-sv',
  close: 'Stäng',
};

export default svTranslation;
