import animalStore from './modules/animal/animal.store';
import authStore from './modules/auth/auth.store';
import bookingStore from './modules/booking/booking.store';
import calendarStore from './modules/booking/calendar/calendar.store';
import clientStore from './modules/client/client.store';
import overlayStore from './modules/overlay/overlay.store';
import partnerStore from './modules/partner/partner.store';
import serviceStore from './modules/service/service.store';
import userStore from './modules/user/user.store';

export default {
  booking: {
    ...bookingStore,
    namespaced: true,
  },
  calendar: {
    ...calendarStore,
    namespaced: true,
  },
  client: {
    ...clientStore,
    namespaced: true,
  },
  overlay: {
    ...overlayStore,
    namespaced: true,
  },
  animal: {
    ...animalStore,
    namespaced: true,
  },
  user: {
    ...userStore,
    namespaced: true,
  },
  auth: {
    ...authStore,
    namespaced: true,
  },
  partner: {
    ...partnerStore,
    namespaced: true,
  },
  service: {
    ...serviceStore,
    namespaced: true,
  },
}