import PartnerCustomer from '../../../interfaces/partner-data/PartnerCustomer';
import User from '../../../interfaces/User';
import PhoneNumber from '../../../models/PhoneNumber';
import UserState from './user.state';

export default {

  state: {
    id: null,
    firstName: '',
    lastName: '',
    email: '',
    phone: PhoneNumber.create('', ''),
  } as UserState,

  mutations: {
    set: (
      state: UserState,
      payload: User,
    ) => {
      Object.assign(state, {
        ...state,
        ...payload,
      });
    },

    setPhone: (
      state: UserState,
      payload: PhoneNumber,
    ) => {
      const phone = {
        ...state.phone,
        ...payload,
      };
      state.phone = PhoneNumber.create(phone.number, phone.countryCode);
    },
  },

  actions: {
    init({ commit }: any, user: PartnerCustomer) {
      commit('set', user);
      commit('setPhone', { number: user.mobilePhone });
    }
  },
}