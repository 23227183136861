import Vue from 'vue';
import { v4 as uuidv4 } from 'uuid';
import WidgetRequirements from './interfaces/partner-data/WidgetRequirements';
import Modal from './Modal.vue';
import store from './store/store';
import config from './config';
import customEvents from './plugins/custom-events';
import NextAvailableSlot from './models/NextAvailableSlot';
import bookingService from './services/booking.service';

export default class WidgetAPI {

  private readonly MODAL_TAG_NAME: string = 'fv-partner-modal';

  private isModalOpen = false;

  private interval: number;

  public openModal = (): void => {
    if (!this.isModalOpen) {
      this.isModalOpen = true;
      this.addModalToDOM();
      this.handlePopstate((): string => {
        return 'Are you sure? Your work will be lost. ';
      });
    }
  }

  public closeModal = (): void => {
    const [ modal ] = document.getElementsByTagName(this.MODAL_TAG_NAME);
    modal.remove();
    this.isModalOpen = false;
    this.handlePopstate(null);
  }

  public initialize = async (data: WidgetRequirements): Promise<any> => {
    this.setIsInitializing(true);
    const countryId = config.COUNTRY_IDS[data.country.toLowerCase()];
    store.commit('client/setCountryId', countryId);
    store.commit('partner/set', {
      id: data.partnerId,
      token: data.token,
      market: data.country,
    });
    store.commit('auth/setSessionId', this.getUuid());

    try {
      await store.dispatch('auth/fetchCountryCodes');
      await store.dispatch('animal/fetchAnimalTypes', countryId);
      store.dispatch('auth/fetchTermsOfService');
      store.dispatch('auth/fetchPrivacyPolicy');
      store.dispatch('auth/fetchCookiesPolicy');
      store.dispatch('animal/init', data.animals);
      store.dispatch('user/init', data.customer);
      await store.dispatch('service/fetchServices');

      this.setIsInitializing(false);
      return true;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  public initNextAvailableSlot = (countryId: number, withInterval = true): void => {
    this.fetchNextAvailableSlot(countryId);
    if (withInterval) {
      this.interval = setInterval(() => {
        this.fetchNextAvailableSlot(countryId);
      }, config.NEXT_AVAILABLE_SLOT_INTERVAL);
    }
  }

  public stopNextAvailableSlot = (): void => {
    clearInterval(this.interval);
  }

  private fetchNextAvailableSlot = (countryId: number): void => {
    bookingService.fetchNextAvailableSlot(countryId)
      .then((nextAvailableSlot: NextAvailableSlot) => {
        customEvents.onFetchedNextAvailableSlot(nextAvailableSlot);
      });
  }

  private addModalToDOM = (): void => {
    Vue.customElement(this.MODAL_TAG_NAME, new Modal().$options);
    const modal = document.createElement(this.MODAL_TAG_NAME);
    document.body.appendChild(modal);
  }

  private setIsInitializing = (isInitializing: boolean): void => {
    store.commit('client/setIsInitializing', isInitializing);
  }

  private handlePopstate = (method: (() => string) | null): void => {
    window.onbeforeunload = method;
  }

  private getUuid = (): string => {
    if (store.getters.getSessionId) {
      return store.getters.getSessionId;
    }

    return uuidv4();
  }
}
