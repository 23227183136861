import { VueConstructor } from 'vue';
import selectAnimal from '../views/Booking/SelectAnimal.vue';
import selectService from '../views/Booking/SelectService.vue';
import selectSlot from '../views/Booking/SelectSlot.vue';
import describeSymptom from '../views/Booking/DescribeSymptom.vue';
import addDetails from '../views/Booking/AddDetails.vue';
import confirmPhoneNumber from '../views/Booking/ConfirmPhoneNumber.vue';
import confirmDetails from '../views/Booking/ConfirmDetails.vue';
import done from '../views/Booking/Done.vue'

const BOOKING_ROUTES: Record<string, VueConstructor<Vue>> = {
  'select-animal': selectAnimal,
  'select-service': selectService,
  'select-slot': selectSlot,
  'describe-symptom': describeSymptom,
  'add-details': addDetails,
  'confirm-details': confirmDetails,
  'confirm-phone-number': confirmPhoneNumber,
  'done': done,
};

export default BOOKING_ROUTES;