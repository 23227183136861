

















































import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      errorMessage: '' as string,
      amountOfImages: 0,
      acceptTypes: ['image/png', 'image/jpeg', 'image/heif', 'image/heic'],
    }
  },
  computed: {
    hasImages(): boolean {
      return this.amountOfImages > 0;
    }
  },
  methods: {
    onUpload(): void {
      const input = this.$refs.imageInput as HTMLElement;
      input.click();
    },
    onFileSelected(event: any): void {
      const {files} = event.target;

      if (!this.validate(files)) {
        return;
      }

      this.amountOfImages = files.length;
      this.errorMessage = '';
      this.$emit('select', files);
    },
    onDelete(): void {
      this.amountOfImages = 0;
      this.errorMessage = '';
      this.$emit('delete');
      this.resetInput();
    },
    validate(files: FileList): boolean {
      if (files.length > 6) {
        this.errorMessage = `${this.$t('images_max_message')}`;
        return false;
      }

      // 10mb limit
      const sizeOfFiles = Array.from(files).reduce((a,b) => {return a+b.size;}, 0);
      if (sizeOfFiles > 1024 * 1024 * 10) {
        this.errorMessage = `${this.$t('image_size_limitation')}`;
        return false;
      }

      const hasIncorrectFormat = Array.from(files).some((file) => !this.acceptTypes.includes(file.type));
      if (hasIncorrectFormat) {
        this.errorMessage = `${this.$t('invalid_file_type_message')}`;
        return false;
      }

      return true;
    },
    resetInput(): void {
      const input = this.$refs.imageInput as HTMLInputElement;
      input.value = '';
    },
  },
})
