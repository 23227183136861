





















import { mapState, mapActions, mapMutations } from 'vuex';
import router from './routes/router';
import store from './store/store';
import i18n from './i18n/i18n';
import BaseModal from './components/misc/BaseModal.vue';
import WidgetModalHeader from './components/modal/WidgetModalHeader.vue';
import Overlays from './components/modal/Overlays.vue';
import config from './config';

export default router.extend({
  i18n,
  store,
  components: {
    BaseModal,
    WidgetModalHeader,
    Overlays,
  },
  watch: {
    isInitializing: {
      handler(init) {
        this.initializeComponent(init);
      },
      deep: true
    },
  },
  computed: {
    ...mapState('client', ['isInitializing']),
    ...mapState(['partner']),
    ...mapState('booking', ['appointmentId']),
    modalClass(): string {
      return this.currentRoute === 'confirm-phone-number' ? 'firstvet-blue' : '';
    },
    shouldValidateBookingOnRoute(): boolean {
      return !config.PREVENT_BOOKING_VALIDATION_ROUTES.includes(this.currentRoute)
       && this.appointmentId > 0;
    }
  },
  mounted() {
    this.$customEvents.onModalOpen();
    this.$trackingEvents.clickedOpenWidget();
    this.$trackingEvents.setUserProperties({
      partnerId: this.partner.id,
      market: this.partner.market,
    });
    this.initializeComponent(this.isInitializing);

    if (this.shouldValidateBookingOnRoute) {
      this.validateBookingData();
    }
  },
  methods: {
    ...mapActions('booking', ['validateBooking']),
    ...mapMutations('booking', ['clearBooking']),
    ...mapMutations('overlay', { openOverlay: 'open', closeOverlay: 'close' }),
    closeModal(): void {
      this.$customEvents.onModalClose();
      window.FirstvetWidgetAPI.closeModal();
    },
    initializeComponent(init: boolean): void {
      if (!init) {
        this.navigateTo({
          name: this.initialView,
          clearHistory: false,
        });
      }
    },
    async validateBookingData(): Promise<void> {
      const result = await this.validateBooking();

      if (!result.isValid) {
        this.openOverlay({
          type: 'alert',
          title: this.$t('something_went_wrong'),
          description: result.message,
          buttonText: this.$t('i_understand'),
          onClose: (): void => {
            this.closeOverlay();
            this.resetRouter();
            this.clearBooking();
            this.initializeComponent(this.isInitializing);
          },
        });
      }
    }
  },
});
