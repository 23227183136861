/* eslint-disable @typescript-eslint/camelcase */
const deTranslation: object = {
  next_available_time: 'Nächster verfügbarer Termin',
  next: 'Nächste',
  with: 'mit',
  select_your_pet: 'Wähle Dein Haustier',
  select_pet_description: 'Über welches Haustier würdest Du gerne mit einem Tierarzt sprechen?',
  select_service: 'Dienstleistung auswählen',
  select_service_description: 'Wähle unten eine Serviceart aus um Deinen Termin zu buchen.',
  select_slot_title: 'Wähle einen Termin',
  select_slot_description: 'Wähle einen Termin für Dein Beratungsgespräch mit einem unserer Tierärzte. Das Treffen findet in unserer App statt.',
  describe_symptom_title: 'Beschreibe die Symptome Deines Haustiers',
  describe_symptoms_description: 'Bitte gib eine kurze Beschreibung der Symptome Deines Haustieres und lade Bilder hoch, die für unseren Tierarzt hilfreich sein könnten (optional).',
  describe_symptoms_placeholder: 'Führe alle Symptome, Beschwerden, Verhaltensprobleme usw.',
  continue: 'Weiter',
  add_images: 'Füge bis zu 6 Bilder hinzu',
  amount_of_images: '{amount} Bilder | {amount} Bilder',
  confirm_phone_number: 'Wir haben Dir eine SMS mit einer {count}-stelligen PIN geschickt. Gib diese ein um die Buchung abzuschließen.',
  wrong_pin: 'Falscher Code',
  please_try_again: 'Bitte versuche es nochmal',
  go_back_to_update_your_phone_number: 'Gehe zurück um die Telefonnummer zu aktualisieren',
  resend_pin_code: 'Code erneut senden',
  almost_there: 'Fast fertig',
  confirm_details_description: 'Bestätige Deine Daten und akzeptiere unsere Nutzungsbedingungen und Datenschutzerklärung.',
  continue_to_final: 'Weiter zum letzten Schritt',
  phone_number: 'Telefonnummer',
  time_of_appointment: 'Uhrzeit des Termins',
  email: 'Email',
  pet: 'Haustier',
  terms_of_use: 'Nutzungsbedingungen',
  cookie_policy: 'Cookie Policy',
  privacy_policy: 'Datenschutzerklärung',
  confirm_read_understood: 'und bestätige, dass ich die folgenden Informationen gelesen und verstanden habe',
  agree_terms: 'Ich akzeptiere die',
  done_title: 'Erfolgreich! Mache jetzt Folgendes.',
  book_another_animal: 'Buche für ein anderes Haustier',
  get_sms_reminder: 'Erhalte eine SMS Erinnerung.',
  login_with_phone: 'Login mit Deiner Handynummer',
  download_app: 'Downloade die FirstVet App',
  booking_complete_instruction_1: 'Die Videosprechstunde findet in der App statt.',
  booking_complete_instruction_2: 'In der App - melde Dich mit {phoneNumber} an.',
  booking_complete_instruction_3: 'Du findest Deine Buchung auf dem Startbildschirm.',
  book: 'Buche',
  minutes_short: 'min',
  tomorrow: 'Morgen',
  today: 'Heute',
  no_available_times: 'Keine freien Termine mehr an diesem Tag.',
  free: 'Frei',
  jump_next_day: 'Springe zum nächsten Tag',
  images_max_message: 'Maximal 6 Bilder!',
  image_size_limitation: 'Die Dateien sind zu groß, das Gesamtlimit beträgt 10 MB!',
  invalid_file_type_message: 'Hochgeladenes Bildformat ist nicht erlaubt!',
  add_phone_number_title: 'Füge Deine Handynummer hinzu',
  add_phone_number_description: 'Wir schicken Dir eine Nachricht per SMS um Deine Buchung zu bestätigen und Du kannst dich mit dieser Handynummer in die App einloggen.',
  phone_placeholder: '162 3744 336',
  terms_of_service: 'Nutzungsbedingungen',
  something_went_wrong: 'Etwas ist schief gelaufen',
  contact_our_support: 'Kontaktiere unseren Support unter support@firstvet.de',
  i_understand: 'Ich verstehe',
  slot_is_already_booked: 'Jemand anderes hat es geschafft, diesen Termin vor Dir zu buchen. Bitte wähle ein anderes Zeitfenster.',
  slot_no_longer_available: 'Termin ist nicht mehr verfügbar',
  slot_no_longer_available_desc: 'Deine Buchung hat etwas zu lange gedauert, der von Dir gewählte Termin ist nicht mehr verfügbar',
  select_a_new_slot: 'Wähle ein neues Zeitfenster',
  sorry: 'Fast geschafft!',
  field_required_message: 'Dieses Feld muss ausgefüllt werden',
  by_continuing_you_agree: 'Wenn Du fortfährst, stimmst Du unseren',
  and_confirm_you_have_read: 'zu und bestätigst, dass Du unsere',
  including_use_of: 'inkl. der Nutzung von',
  cookies: 'Cookies',
  tos_text_de_ending: ', gelesen und verstanden hast',
  support_email: 'support@firstvet.de',
  no_pin_code_received: 'Keinen PIN-Code erhalten?',
  no_pin_code_download_app: 'Wenn Sie keinen PIN-Code erhalten haben, laden Sie bitte die App herunter, um die Beratung zu buchen.' +
  '<br/>Melden Sie sich in der App mit Ihrer Telefonnummer an.' +
  '<br/><br/><a href="{appUrl}" target="_blank" class="download-app">Downloade die FirstVet App</a>' + 
  '<br/><br/>Wenn Sie den PIN-Code erhalten haben, schließen Sie bitte diese Nachricht, um den Code einzugeben.',
  app_url: 'https://firstvet.page.link/app-de',
  close: 'Schließen',
};

export default deTranslation;
