
import ConfirmBookingPayload from '../interfaces/booking/payloads/ConfirmBookingPayload';
import ReserveSlotPayload from '../interfaces/booking/payloads/ReserveSlotPayload';
import AvailableSlotPayload from '../interfaces/booking/payloads/AvailableSlotPayload';
import UploadImageResponse from '../interfaces/booking/responses/UploadImageResponse';
import ValidateBookingResponse from '../interfaces/booking/responses/ValidateBookingResponse';
import AvailableSlot from '../models/booking/AvailableSlot';
import NextAvailableSlot from '../models/NextAvailableSlot';
import Service from '../models/Service';
import http from '../plugins/http';

export default {
  async fetchServices(payload: any): Promise<Array<Service>> {
    const result = await http.partnerPost('services', payload);
    return result.map((service: Service) => Service.formatHttpResponse(service));
  },

  async fetchNextAvailableSlot(countryId: number): Promise<NextAvailableSlot> {
    const result = await http.partnerGet(`appointmentdata/next/${countryId}`);
    return NextAvailableSlot.formatHttpResponse(result.next || {});
  },

  async fetchAvailableSlots(payload: AvailableSlotPayload): Promise<Array<AvailableSlot>> {
    const result = await http.partnerGet('slots/available', payload);
    return result.map((slot: any) => AvailableSlot.formatHttpResponse(slot));
  },

  async reserveSlot(payload: ReserveSlotPayload): Promise<object> {
    return http.partnerPost('reserve/slot', payload);
  },

  async confirmBooking(payload: ConfirmBookingPayload): Promise<object> {
    return http.partnerPost('confirm-booking', payload);
  },

  async setSymptomDescription(description: string, appointmentId: number): Promise<object> {
    return http.partnerPost(`booking/${appointmentId}/description`, { description });
  },

  async uploadImages(
    partnerId: number, userId: number,
    appointmentId: number,
    fd: FormData
  ): Promise<UploadImageResponse> {
    return http.uploadFile(`upload/${partnerId}/${userId}/${appointmentId}`, fd);
  },

  async validateBooking(appointmentId: number): Promise<ValidateBookingResponse> {
    return http.partnerGet(`booking/${appointmentId}/validate`);
  }
};
