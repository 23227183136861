export default class Service {

  constructor(
    public id: number,
    public key: string,
    public title: string,
    public description: string,
  ) { }

  static formatHttpResponse(response: any): Service {
    return new this(
      response.id,
      response.key,
      response.service_translation.title,
      response.service_translation.description,
    );
  }
}
