import http from '../plugins/http';
import ManagePartnerCustomerResponse from '../interfaces/auth/responses/ManagePartnerCustomerResponse';
import RequestConfirmationCodeResponse from '../interfaces/auth/responses/RequestConfirmationCodeResponse';
import VerifyConfirmationCodeResponse from '../interfaces/auth/responses/VerifyConfirmationCodeResponse';
import CountryCode from '../models/CountryCode';
import CookiesPolicy from '../models/CookiesPolicy';
import PrivacyPolicy from '../models/PrivacyPolicy';
import TermsOfService from '../models/TermsOfService';
import CountryCodeResponse from '../interfaces/auth/responses/CountryCodeResponse';
import ManagePartnerCustomerPayload from '../interfaces/auth/payloads/ManagePartnerCustomerPayload';
import RequestConfirmationCodePayload from '../interfaces/auth/payloads/RequestConfirmationCodePayload';
import VerifyConfirmationCodePayload from '../interfaces/auth/payloads/VerifyConfirmationCodePayload';

export default {

  async fetchCountryCodes(): Promise<Array<CountryCode>> {
    const response = await http.partnerGet('country-codes');
    return response.country_codes
      .map((countryCode: CountryCodeResponse) => CountryCode.formatHttpResponse(countryCode));
  },

  async fetchTermsOfService(countryId: number): Promise<TermsOfService> {
    const response = await http.cmsRequest(`${countryId}/tos`);
    const [tos] = response.data;
    return TermsOfService.formatHttpResponse(tos);
  },

  async fetchPrivacyPolicy(countryId: number): Promise<PrivacyPolicy> {
    const response = await http.cmsRequest(`${countryId}/privacy-policy`);
    const [policy] = response.data;
    return PrivacyPolicy.formatHttpResponse(policy);
  },

  async fetchCookiesPolicy(countryId: number): Promise<CookiesPolicy> {
    const response = await http.cmsRequest(`${countryId}/cookies`);
    const [policy] = response.data;
    return CookiesPolicy.formatHttpResponse(policy);
  },

  async managePartnerCustomer(payload: ManagePartnerCustomerPayload): Promise<ManagePartnerCustomerResponse> {
    return http.partnerPost('manage-partner-customer', payload);
  },

  async requestConfirmationCode(payload: RequestConfirmationCodePayload): Promise<RequestConfirmationCodeResponse> {
    return http.partnerPost('request-confirmation-code', payload);
  },

  async verifyConfirmationCode(payload: VerifyConfirmationCodePayload): Promise<VerifyConfirmationCodeResponse> {
    return http.partnerPost('verify-confirmation-code', payload);
  },
}
