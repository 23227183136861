






















































import Vue from 'vue';
import { mapState, mapMutations, mapActions } from 'vuex';
import PageHeader from '@/components/modal/PageHeader.vue';
import ListItemNavigation from '@/components/list-items/ListItemNavigation.vue';
import ListItemAction from '@/components/list-items/ListItemAction.vue';
import AcceptTos from '@/components/misc/AcceptTos.vue';
import ListItemDivider from '@/components/list-items/ListItemDivider.vue';
import ProvidedInformation from '@/components/misc/ProvidedInformation.vue';
import dayjs from '../../day-js';

export default Vue.extend({
  components: {
    PageHeader,
    ListItemNavigation,
    ListItemAction,
    AcceptTos,
    ListItemDivider,
    ProvidedInformation,
  },
  data() {
    return {
      hasAcceptedTerms: false,
    };
  },
  computed: {
    ...mapState(['booking', 'user']),
    ...mapState('animal', { animal: 'selected' }),
    bookingTime(): string {
      return dayjs(this.booking.slot.dateInfo.date).calendar();
    },
  },
  methods: {
    ...mapActions('auth', ['managePartnerCustomer']),
    ...mapActions('overlay', ['showLoadingSpinner']),
    ...mapMutations('overlay', { openOverlay: 'open', closeOverlay: 'close' }),
    setAcceptedTerms(hasAccepted: boolean): void {
      this.$trackingEvents.clickAcceptTosWidget();
      this.hasAcceptedTerms = hasAccepted;
    },
    async save(): Promise<void> {
      this.$trackingEvents.clickedVerifyBookingWidget();
      this.showLoadingSpinner(true);
      const res = await this.managePartnerCustomer();
      this.showLoadingSpinner(false);

      if (res) {
        this.$emit('navigate', { name: 'confirm-phone-number' });
      } else {
        this.openOverlay({
          type: 'alert',
          title: this.$t('bad_user_credentials_title'),
          description: this.$t('bad_user_creation_credentials', {
            loginUrl: `${process.env.VUE_APP_BASEURL}/${localStorage.getItem('fv-country')}/login`,
            supportEmail: this.$t('support_email'),
          }),
          buttonText: this.$t('i_understand'),
          onClose: (): void => {
            this.closeOverlay();
          },
        });
      }
    },
  },
})
