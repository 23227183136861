import Vue from 'vue';
import vueCustomElement from 'vue-custom-element';
import InlineSvg from 'vue-inline-svg';
import setupValidation from '@/plugins/validationConfig';
import DEFAULT_DATA from '../demo/DEFAULT_DATA';
import Widget from './Widget.vue';
import WidgetAPI from './WidgetAPI';
import '@/assets/app.css';
import '@/components/containers/containers.ts';
import '@/components/global-components.ts';
import './plugins/http';
import './plugins/custom-events';
import './plugins/tracking';

window.FirstvetWidgetAPI = new WidgetAPI();
Vue.config.productionTip = false;

setupValidation();

Vue.use(vueCustomElement);
Vue.customElement('fv-partner-widget', new Widget().$options);

Vue.component('inline-svg', InlineSvg);

if (process.env.VUE_APP_LOCAL_BUILD === 'true') {
  new Vue({
    render: (h) => h(Widget, {
      props: DEFAULT_DATA,
    }),
  }).$mount('#app');
}
