








































import Vue from 'vue';
import { mapActions, mapState, mapMutations } from 'vuex';
import LinkButton from '@/components/misc/LinkButton.vue';
import ListItemPinWrapper from '@/components/list-items/ListItemPinWrapper.vue';
import PageHeader from '@/components/modal/PageHeader.vue';
import config from '@/config.ts';

export default Vue.extend({
  name: 'ConfirmPhoneNumber',
  components: {
    LinkButton,
    ListItemPinWrapper,
    PageHeader,
  },
  data() {
    return {
      pinCodeLength: config.PIN_CODE_LENGTH as number,
      verificationState: '' as string
    };
  },
  computed: {
    ...mapState(['user']),
  },
  mounted() {
    this.$trackingEvents.clickedAddPhoneWidget();
    this.requestCode();
  },
  methods: {
    ...mapActions('auth', ['requestConfirmationCode', 'verifyConfirmationCode']),
    ...mapActions('booking', ['confirmBooking']),
    ...mapActions('overlay', ['showLoadingSpinner']),
    ...mapActions('animal', ['addAnimals']),
    ...mapMutations('overlay', { openOverlay: 'open', closeOverlay: 'close' }),
    async requestCode(): Promise<void> {
      await this.requestConfirmationCode();
    },
    async verifyCode(code: string): Promise<void> {
      this.verificationState = '';
      this.showLoadingSpinner(true);
      const res = await this.verifyConfirmationCode(code);
      if (res) {
        this.$trackingEvents.typedCorrectPinWidget();
        const r = await this.addAnimals();
        if (r) {
          const result = await this.confirmBooking();
          this.showLoadingSpinner(false);
          if (result) {
            this.$emit('navigate', { name: 'done', clearHistory: true });
          } else {
            this.showErrorMessage();
          }
        } else {
          this.showErrorMessage();
        }
      } else {
        this.$trackingEvents.typedWrongPinWidget();
        this.showLoadingSpinner(false);
        this.verificationState = 'error';
      }
    },
    showErrorMessage() {
      this.openOverlay({
        type: 'alert',
        title: this.$t('slot_no_longer_available'),
        description: this.$t('slot_no_longer_available_desc'),
        buttonText: this.$t('select_a_new_slot'),
        onClose: (): void => {
          this.closeOverlay();
          this.$emit('navigate', { name: 'select-slot'});
        },
      });
    },
    showNoPinCodeMessage() {
      this.openOverlay({
        type: 'alert',
        title: this.$t('no_pin_code_received'),
        description: this.$t('no_pin_code_download_app', {
          appUrl: this.$t('app_url')
        }),
        buttonText: this.$t('close'),
        onClose: () => {
          this.closeOverlay();
        },
      });
    },
  }
})
