import Firebase from './firebase';
import PartnerState from '../store/modules/partner/partner.state';
import BookingState from '../store/modules/booking/booking.state';
import Animal from '../models/Animal';
import Service from '../models/Service';

export default class TrackingEvents extends Firebase {

  constructor() {
    super();
    try {
      this.setup();
    } catch(e) {
      console.error(e);
    }
  }

  public clickedOpenWidget = (): void => {
    this.fireEvent('clicked_open_widget');
  }

  public clickedSelectPetWidget = (animal: Animal): void => {
    this.fireEvent('clicked_select_pet_widget', {
      petType: animal.type.key,
    });
  }

  public clickedSelectServiceWidget = (service: Service): void => {
    this.fireEvent('clicked_select_pet_widget', {
      serviceId: service,
    });
  }

  public clickedSelectTimeSlotWidget = (): void => {
    this.fireEvent('clicked_select_time_slot_widget');
  }

  public selectedDayWidget = (): void => {
    this.fireEvent('selected_day_widget');
  }

  public clickedUploadImagesWidget = (): void => {
    this.fireEvent('clicked_upload_images_widget');
  }

  public clickedSubmitdescriptionWidget = (): void => {
    this.fireEvent('clicked_submit_description_widget');
  }

  public clickedAddPhoneWidget = (): void => {
    this.fireEvent('clicked_add_phone_widget');
  }

  public clickAcceptTosWidget = (): void => {
    this.fireEvent('click_accept_tos_widget');
  }

  public clickedViewTosWidget = (): void => {
    this.fireEvent('clicked_view_tos_widget');
  }

  public clickedViewPrivacyPolicyWidget = (): void => {
    this.fireEvent('clicked_view_privacy_policy_widget');
  }

  public clickedViewCookiesWidget = (): void => {
    this.fireEvent('clicked_view_cookies_widget');
  }

  public clickedVerifyBookingWidget = (): void => {
    this.fireEvent('clicked_verify_booking_widget');
  }

  public typedWrongPinWidget = (): void => {
    this.fireEvent('typed_wrong_pin_widget');
  }

  public typedCorrectPinWidget = (): void => {
    this.fireEvent('typed_correct_pin_widget');
  }

  public bookingSuccessfulWidget = (
    animal: Animal,
    partner: PartnerState,
    booking: BookingState
  ): void => {
    this.fireEvent('booking_successful_widget', {
      petType: animal.type.key,
      partnerId: partner.id,
      market: partner.market,
      hasDescription: booking.description.length > 0
    });
  }

  public clickedAppstoreBadgeWidget = (linkType: string): void => {
    this.fireEvent('clicked_appstore_badge_widget', {
      linkType,
    });
  }

  public clickedTimeNaSelectNewWidget = (): void => {
    this.fireEvent('clicked_time_na_select_new_widget');
  }
}