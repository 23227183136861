












import Vue from 'vue';
import BaseButton from '../misc/BaseButton.vue';
import ActivitySpinner from '../spinners/ActivitySpinner.vue';

export default Vue.extend({
  name: 'ListItemAction',
  components: {
    BaseButton,
    ActivitySpinner,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    isDisabled(): boolean {
      return this.disabled || this.loading;
    }
  },
})
