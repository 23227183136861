<template>
    <p
        class="badge-text"
        :style="{ background: color }"
    >
        {{ text }}
    </p>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '#0061FF',
    }
  },
}
</script>

<style lang="postcss" scoped>
.badge-text {
  @apply fv-text-white fv-uppercase fv-font-bold;
  padding: 1px 8px;
  border-radius: 8px 8px 8px 2px;
  font-size: 11px;
  line-height: 18px;
}
</style>