import dayjs from '../../../day-js';
import config from '../../../config';
import i18n from '../../../i18n/i18n';
import PartnerState from './partner.state';

export default {

  state: {
    id: 0,
    token: '',
    market: '',
  } as PartnerState,

  getters: {
    market: (
      state: PartnerState,
    ) => state.market,
  },

  mutations: {
    set: (
      state: PartnerState,
      payload: any,
    ) => {
      Object.assign(state, {
        ...state,
        ...payload,
      });
      const locale = config.COUNTRY_LOCALES[payload.market] || `${i18n.fallbackLocale}`;
      i18n.locale = locale;
      dayjs.locale(locale);
    },
  },
}
