import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import localizedFormat from 'dayjs/plugin/localizedFormat';

// Locales
import 'dayjs/locale/en-gb'
import 'dayjs/locale/sv';
import 'dayjs/locale/de';

dayjs.extend(calendar);
dayjs.extend(localizedFormat);

export default dayjs;
