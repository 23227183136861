










import Vue from 'vue';

export default Vue.extend({
  name: 'DownloadAppBadge',
  props: {
    image: {
      type: String,
      default: '',
      required: true,
    }
  }
})
