import OverlayState from './overlay.state';

const createState = (): object => ({
  show: false,
  type: '',
  title: '',
  description: '',
  buttonText: '',
  onClose: null,
});

export default {
  state: createState() as OverlayState,

  mutations: {
    open: (
      state: any,
      payload: any
    ) => {
      Object.assign(state, {
        show: true,
        ...payload,
      });
    },

    close: (
      state: any
    ) => {
      Object.assign(state, { ...createState() })
    },
  },

  actions: {
    showLoadingSpinner({ commit }: any, show: boolean) {
      if (show) {
        commit('open', { type: 'loading-spinner' });
      } else {
        commit('close');
      }
    }
  },
}