import Vue from 'vue';
import Vuex from 'vuex';
import persistence from './persistence';
import bookingService from '../services/booking.service';
import NextAvailableSlot from '../models/NextAvailableSlot';
import storeModules from './store.modules';

Vue.use(Vuex);

export default new Vuex.Store({

  modules: storeModules,

  state: {
    nextAvailableSlot: null as NextAvailableSlot | null,
  },

  mutations: {
    setNextAvailableSlot: (
      state: any,
      nextAvailableSlot: NextAvailableSlot
    ) => {
      state.nextAvailableSlot = nextAvailableSlot;
    },
  },

  actions: {
    fetchNextAvailableSlot({ commit }: any, countryId: number) {
      bookingService.fetchNextAvailableSlot(countryId)
        .then((nextAvailableSlot: NextAvailableSlot) => {
          commit('setNextAvailableSlot', nextAvailableSlot);
        });
    },
  },

  plugins: [persistence.plugin],
});
