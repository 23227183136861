export default class Vet {

  constructor(
    public id: number,
    public displayName: string,
    public avatar: string,
    public languages: Array<any>,
  ) { }

  static formatHttpResponse(response: any): Vet {
    return new this(
      response.id,
      response.display_name,
      response.avatar || '',
      response.languages,
    );
  }
}
