







import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  props: {
    avatarName: {
      type: String,
      default: '',
      required: true,
    },
  },
  computed: {
    ...mapGetters('client', { isSwedish: 'getIsSwedish' }),
    avatarUrl(): string {
      const webUrl = this.isSwedish ? process.env.VUE_APP_SV_BASE_URL : process.env.VUE_APP_INTL_BASE_URL;
      return this.avatarName
        ? `${webUrl}/upload/users/${this.avatarName}`
        : 'https://cdn.firstvet.app/assets/logos/firstvet-logo-small.png';
    },
  },
})
