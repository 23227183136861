import AwesomePhonenumber from 'awesome-phonenumber';

export default class PhoneNumber {

  constructor(
    public number: string,
    public countryCode: string,
  ) { }

  static create(number: string, countryCode: string): PhoneNumber {
    return new this(
      number,
      countryCode,
    );
  }

  public isValid = (): boolean => {
    const phone = this.getPhoneNumber();
    return phone.isValid();
  }

  public formatted = (): string => {
    const phone = this.getPhoneNumber();
    return phone.getNumber();
  }

  public internationallyFormatted = (): string => {
    const phone = this.getPhoneNumber();
    return phone.getNumber('international');
  }

  private getPhoneNumber = (): AwesomePhonenumber => {
    const regionCode = AwesomePhonenumber.getRegionCodeForCountryCode(parseInt(this.countryCode, 10));
    // eslint-disable-next-line
    return new AwesomePhonenumber(this.number, regionCode);
  }
}