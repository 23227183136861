
























import Vue from 'vue';
import ListItemPinInput from './ListItemPinInput.vue';

export default Vue.extend({
  name: 'ListItemPinWrapper',
  components: {
    ListItemPinInput,
  },
  props: {
    state: {
      type: String,
      default: '',
    },
    pinCodeLength: {
      type: Number,
      default: 6,
    }
  },
  data() {
    return {
      pins: {} as Record<number, any>,
      codeArr: [] as Array<string>,
      error: false,
      animationDuration: 2200,
    }
  },
  watch: {
    state(value: string) {
      if (value === 'error') {
        this.setErrorState();
      }
    },
  },
  mounted() {
    this.pins = this.$refs.pin as Record<number, any>;
    this.codeArr = [...Array(this.pinCodeLength).keys()].map(() => '');
  },
  methods: {
    handlePinInput(index: number): void {
      setTimeout(() => {
        const insertingValue = Boolean(this.pins[index].$el.value);
        const canIncrease = index < (this.pinCodeLength - 1);
        const canDecrease = index > 0;

        if (insertingValue) {
          if (canIncrease) {
            this.pins[index + 1].$el.focus();
          } else {
            this.$emit('verify-code', this.codeArr.join(''));
          }
        } else if (canDecrease) {
          this.pins[index - 1].$el.focus();
        }
      }, 20);
    },
    setErrorState(): void {
      this.error = true;
      setTimeout(() => {
        this.error = false;
      }, this.animationDuration)
    }
  },
})
