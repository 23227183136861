import { render, staticRenderFns } from "./ConfirmPhoneNumber.vue?vue&type=template&id=d3680c0c&scoped=true&"
import script from "./ConfirmPhoneNumber.vue?vue&type=script&lang=ts&"
export * from "./ConfirmPhoneNumber.vue?vue&type=script&lang=ts&"
import style0 from "./ConfirmPhoneNumber.vue?vue&type=style&index=0&id=d3680c0c&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d3680c0c",
  null
  
)

export default component.exports