import VuexPersistence from 'vuex-persist';
import config from '../config';

export default new VuexPersistence({
  key: config.VUEX_PERSIST_KEY,
  storage: window.localStorage,
  reducer: (state: any) => {
    const {
      booking,
      partner,
      animal,
      user,
      auth,
    } = state;
    return {
      booking,
      partner,
      animal,
      user,
      auth,
    }
  }
})