import AnimalType from './AnimalType';

export default class Animal {

  constructor(
    public name: string,
    public type: AnimalType,
    public uid: string,
    public breed: string,
    public birthDate: string,
    public policyNumber: string,
  ) { }

  static create(data: any): Animal {
    return new this(
      data.name,
      data.type,
      data.uid,
      data.breed,
      data.birthDate,
      data.policyNumber,
    );
  }
}
