import dayjs from '../../day-js';
import AvailableSlot from '../booking/AvailableSlot';
import CalendarDate from './CalendarDate';

export default class CalendarListItem {

  constructor(
    public calendarDate: CalendarDate,
    public slots: Array<AvailableSlot>,
    public slotsLoaded: boolean,
    public disabled: boolean,
  ) { }

  static create(dayIndex: number): CalendarListItem {
    const date = dayjs().add(dayIndex, 'days');

    return new this(
      CalendarDate.create(date),
      [],
      false,
      false
    );
  }
}
