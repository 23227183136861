










import Vue from 'vue';
import { mapState } from 'vuex';
import PageHeader from '../modal/PageHeader.vue';

export default Vue.extend({
  name: 'Cookies',
  components: {
    PageHeader,
  },
  computed: {
    ...mapState(['partner']),
    ...mapState('auth', ['cookies']),
  },
  mounted() {
    const lang = (this.partner.market === 'uk' || this.partner.market === 'us')
      ? 'en' 
      : this.partner.market;

    const CookieDeclaration = document.createElement('script');
    CookieDeclaration.setAttribute(
      'src',
      'https://consent.cookiebot.com/93d5b2d4-2f57-458e-acaf-bfdd4372c865/cd.js'
    );
    CookieDeclaration.setAttribute('async', 'true');
    CookieDeclaration.setAttribute('id', 'CookieDeclaration');
    CookieDeclaration.setAttribute('type', 'text/javascript');
    CookieDeclaration.setAttribute('data-culture', lang);

    const paragraphs = [...document.getElementsByTagName('p')];

    const element = paragraphs.find((p: HTMLElement) => {
      return p.innerHTML.includes('COOKIEBOT_TABLE');
    });

    if (element) {
      element.innerHTML = '';

      const parentDiv = element.parentNode as any;
      parentDiv.insertBefore(CookieDeclaration, element);
    }
  },
});
