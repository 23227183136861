import dayjs from '../../../day-js';

export default class DateInfo {

  constructor(
    public date: number,
    public time: string,
  ) { }

  static formatHttpResponse(response: any): DateInfo {
    return new this(
      response.dateTime,
      dayjs(response.dateTime).format('LT'),
    );
  }
}
