import animalService from '../../../services/animal.service';
import Animal from '../../../models/Animal';
import AnimalState from './animal.state';
import AnimalType from '../../../models/AnimalType';
import PartnerAnimal from '../../../interfaces/partner-data/PartnerAnimal';

export default {

  state: {
    list: [],
    selected: {},
    types: [],
  } as AnimalState,

  getters: {
    hasMultipleAnimals: (
      state: AnimalState,
    ) => state.list.length > 1,
  },

  mutations: {
    setList: (
      state: AnimalState,
      animals: Array<Animal>,
    ) => {
      state.list = animals;
    },

    setSelected: (
      state: AnimalState,
      animal: Animal,
    ) => {
      state.selected = animal;
    },

    setTypes: (
      state: AnimalState,
      types: Array<AnimalType>,
    ) => {
      state.types = types;
    },
  },

  actions: {
    init({ commit, state }: any, partnerAnimals: Array<PartnerAnimal>) {
      const animals = partnerAnimals.map((animal: PartnerAnimal) => Animal.create({
        ...animal,
        type: state.types.find((type: AnimalType) => type.key === animal.type),
      }));

      if (animals.length > 1) {
        commit('setList', animals);
      } else {
        const [ animal ] = animals;
        commit('setSelected', animal);
      }
    },
    fetchAnimalTypes({ commit }: any, countryId: number) {
      return animalService.fetchAnimalTypes(countryId)
        .then((animalTypes: Array<AnimalType>) => {
          commit('setTypes', animalTypes);
        })
        .catch(console.error);
    },
    addAnimals({ state, rootState }: any) {
      return animalService.addAnimals({
        userId: rootState.user.id,
        appointmentId: rootState.booking.appointmentId,
        animals: state.list.length ? state.list : [ state.selected ],
        selectedAnimalUid: state.selected.uid,
      })
        .then((res) => res)
        .catch(console.error);
    },
  },
}