import Vue from 'vue';
import VeeValidate, { Validator } from 'vee-validate';
import * as de from 'vee-validate/dist/locale/de';
import * as se from 'vee-validate/dist/locale/sv';
import * as en from 'vee-validate/dist/locale/en';
import i18n from '../i18n/i18n';

const validationMessages = {
  uk: en,
  se,
  de
};

const dict = Object.keys(validationMessages).reduce((acc, lang) => ({
  ...acc,
  [lang]: {
    ...validationMessages[lang],
    messages: {
      ...validationMessages[lang].messages,
    },
  },
}), {});

const setupValidation = () => {
  VeeValidate.setMode('lazy');
  Vue.use(VeeValidate, {
    i18n,
    locale: i18n.locale,
    classes: true,
    classNames: {
      valid: 'is-valid',
      invalid: 'is-invalid',
    },
  });

  Validator.localize(dict);
};

export default setupValidation;
