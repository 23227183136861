import CookiesPolicyResponse from '../interfaces/auth/responses/CookiesPolicyResponse';

export default class PrivacyPolicy {

  constructor(
    public isLoading: boolean,
    public html: string,
  ) { }

  static formatHttpResponse(response: CookiesPolicyResponse) {
    return new this(
      false,
      response.rich,
    );
  }
}
