import { render, staticRenderFns } from "./AddDetails.vue?vue&type=template&id=275217e1&scoped=true&"
import script from "./AddDetails.vue?vue&type=script&lang=ts&"
export * from "./AddDetails.vue?vue&type=script&lang=ts&"
import style0 from "./AddDetails.vue?vue&type=style&index=0&id=275217e1&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "275217e1",
  null
  
)

export default component.exports