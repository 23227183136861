






import Vue from 'vue';
import BaseSpinner from '../misc/BaseSpinner.vue';

export default Vue.extend({
  name: 'PageSpinner',
  components: {
    BaseSpinner
  },
})
