






































import Vue from 'vue';
import { mapActions, mapState, mapMutations } from 'vuex';
import PageHeader from '@/components/modal/PageHeader.vue';
import InputMultipleRows from '@/components/inputs/InputMultipleRows.vue';
import ListItemAction from '@/components/list-items/ListItemAction.vue';
import UploadImages from '@/components/misc/UploadImages.vue';

export default Vue.extend({
  components: {
    PageHeader,
    InputMultipleRows,
    ListItemAction,
    UploadImages,
  },
  data() {
    return {
      isSaving: false,
    };
  },
  computed: {
    ...mapState('booking', ['description', 'images']),
    desc: {
      set (desc) {
        this.$store.commit('booking/setSymptomDescription', desc);
      },
      get (): string {
        return this.description;
      },
    }
  },
  methods: {
    ...mapActions('booking', ['setSymptomDescription', 'storeImages']),
    ...mapMutations('booking', ['setImages', 'clearImages']),
    async selectImages(images: Array<File>) {
      this.$trackingEvents.clickedUploadImagesWidget();
      this.setImages(Object.values(images));
    },
    async save(): Promise<void> {
      if (await this.$validator.validate()) {
        this.$trackingEvents.clickedSubmitdescriptionWidget();
        this.isSaving = true;
        await this.setSymptomDescription();
        this.isSaving = false;
        this.$emit('navigate', { name: 'add-details' });
      }
    },
  },
})
