import dayjs from '../../day-js';
import i18n from '../../i18n/i18n';

export default class CalendarDate {

  constructor(
    public title: string,
    public value: string,
  ) { }

  static create(date: any): CalendarDate {
    return new this(
      `${this.getDayName(date)} • ${date.format('LL').split(',')[0]}`,
      this.format(date),
    );
  }

  public static format(date: any): string {
    return dayjs(date).format('YYYY-MM-DD');
  }

  public static isToday(date: string): boolean {
    return dayjs(date).isSame(new Date(), 'day');
  }

  private static getDayName(date: any): string {
    const now = dayjs();
    const dayjsDate = dayjs(date);
    if (dayjsDate.isSame(now, 'day')) {
      return i18n.t('today') as string;
    } if (dayjsDate.isSame(now.add(1, 'days'), 'day')) {
      return i18n.t('tomorrow') as string;
    }
    return dayjsDate.format('dddd');
  }
}
