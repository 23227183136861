import CountryCodeResponse from '../interfaces/auth/responses/CountryCodeResponse';

export default class CountryCode {

  constructor(
    public id: number,
    public code: string,
    public countryId: number,
  ) { }

  static formatHttpResponse(response: CountryCodeResponse) {
    return new this(
      response.id,
      response.code,
      response.country_id,
    );
  }
}