import { render, staticRenderFns } from "./ListItemAction.vue?vue&type=template&id=0c27bafa&scoped=true&"
import script from "./ListItemAction.vue?vue&type=script&lang=ts&"
export * from "./ListItemAction.vue?vue&type=script&lang=ts&"
import style0 from "./ListItemAction.vue?vue&type=style&index=0&id=0c27bafa&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c27bafa",
  null
  
)

export default component.exports