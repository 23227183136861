









import Vue from 'vue';

export default Vue.extend({
  name: 'OverlayContainer',
  props: {
    color: {
      type: String,
      default: '',
    }
  }
})
