

























import Vue from 'vue'
import { mapMutations, mapState, mapGetters, mapActions } from 'vuex';
import ListItemNavigation from '@/components/list-items/ListItemNavigation.vue';
import ListItemDivider from '@/components/list-items/ListItemDivider.vue';
import PageHeader from '@/components/modal/PageHeader.vue';
import Animal from '../../models/Animal';

export default Vue.extend({
  components: {
    PageHeader,
    ListItemNavigation,
    ListItemDivider,
  },
  computed: {
    ...mapGetters('service', ['multipleServicesAvailable']),
    ...mapState('animal', { animalList: 'list' }),
  },
  methods: {
    ...mapMutations('animal', ['setSelected']),
    ...mapActions('service', ['fetchServices']),
    ...mapActions('overlay', ['showLoadingSpinner']),
    async selectAnimal(animal: Animal): Promise<void> {
      this.setSelected(animal);
      this.$trackingEvents.clickedSelectPetWidget(animal);

      this.showLoadingSpinner(true);
      await this.fetchServices();
      this.showLoadingSpinner(false);

      if (this.multipleServicesAvailable) {
        this.$emit('navigate', { name: 'select-service' });
      } else {
        this.$emit('navigate', { name: 'select-slot' });
      }
    },
  },
})
