






































import Vue from 'vue';

export default Vue.extend({
  name: 'ListItemInputPhone',
  props: {
    title: {
      type: String,
      default: '',
      required: true,
    },
    countryCodes: {
      type: Array,
      default: () => [],
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
    selectedCode: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  methods: {
    inputFocus() {
      (this.$refs.input as HTMLElement).focus();
    }
  }
})
