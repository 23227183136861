











import Vue from 'vue';
import CheckboxIcon from '../icons/CheckboxIcon.vue';
import TosInformationText from './TosInformationText.vue';

export default Vue.extend({
  components: {
    CheckboxIcon,
    TosInformationText,
  },
  props: {
    checked: {
      type: Boolean,
      default: false,
    }
  }
})
