import DateInfo from './Slot/DateInfo';
import Price from './Slot/Price';
import ServiceInfo from './Slot/ServiceInfo';
import Vet from './Slot/Vet';

export default class AvailableSlot {

  constructor(
    public id: string,
    public dateInfo: DateInfo,
    public vet: Vet,
    public serviceInfo: ServiceInfo,
    public price: Price | null,
    public duration: number,
  ) { }

  static formatHttpResponse(response: any): AvailableSlot {
    return new this(
      response.id,
      DateInfo.formatHttpResponse(response.dateTime),
      Vet.formatHttpResponse(response.user),
      ServiceInfo.formatHttpResponse(response.serviceInfo),
      response.price ? Price.formatHttpResponse(response.price) : null,
      response.duration,
    );
  }
}
