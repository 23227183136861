







































import Vue from 'vue';

export default Vue.extend({
  props: {
    title: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    characterLimit: {
      type: Number,
      default: 0,
    },
    state: {
      type: String,
      default: '',
    },
  }
})
