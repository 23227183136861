












import Vue from 'vue';

export default Vue.extend({
  name: 'BaseSpinner',
  props: {
    color: {
      type: String,
      default: '',
    },
    size: {
      type: Number,
      default: 60,
    }
  },
})
