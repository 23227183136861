








import Vue from 'vue';

export default Vue.extend({
  props: {
    color: {
      type: String,
      default: 'default',
    },
    size: {
      type: String,
      default: 'medium',
    },
  },
  data() {
    return {
      sizes: {
        'small': 80,
        'medium': 116,
        'large': 160,
      } as Record<string, number>,
    };
  },
  computed: {
    logoUrl(): string {
      if (this.color === 'white') {
        return 'https://cdn.firstvet.app/assets/logos/firstvet-logo-white.svg';
      }
      return 'https://cdn.firstvet.app/assets/logos/firstvet-logo.svg';
    },
    logoSize(): number{
      return this.sizes[this.size];
    },
  },
})
