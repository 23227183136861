











import Vue from 'vue';

export default Vue.extend({
  name: 'BaseModal',
  props: {
    stylingClass: {
      type: String,
      default: '',
    },
  },
})
