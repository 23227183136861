export default class Price {

  constructor(
    public amount: string,
    public currency: string,
    public currency_code: string,
  ) { }

  static formatHttpResponse(response: any): Price {
    return new this(
      response.amount,
      response.currency,
      response.currency_code,
    );
  }
}
