import CountryCode from '../../../models/CountryCode';
import CookiesPolicy from '../../../models/CookiesPolicy';
import PrivacyPolicy from '../../../models/PrivacyPolicy';
import TermsOfService from '../../../models/TermsOfService';

export default class AuthState {

  constructor(
    public jwt: string,
    public countryCodes: Array<CountryCode>,
    public sessionId: string,
    public termsOfService?: TermsOfService,
    public privacyPolicy?: PrivacyPolicy,
    public cookies?: CookiesPolicy,
  ) { }

  static create(
    jwt: string,
    countryCodes: Array<CountryCode>,
    sessionId: string,
    termsOfService?: TermsOfService,
    privacyPolicy?: PrivacyPolicy,
    cookies?: CookiesPolicy
  ): AuthState {
    return new this(
      jwt,
      countryCodes,
      sessionId,
      termsOfService,
      privacyPolicy,
      cookies,
    );
  }
}