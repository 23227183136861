

















import Vue from 'vue';

export default Vue.extend({
  name: 'CheckboxIcon',
  components: {},
  props: {
    checked: {
      type: Boolean,
      default: false,
      required: true,
    }
  },
  data() {
    return {
      isChecked: this.checked,
    };
  },
  methods: {
    check(): void {
      this.isChecked = !this.isChecked;
      this.$emit('check', this.isChecked);
    }
  },
})
