



















































import Vue from 'vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import DatePicker from '@/components/list-items/DatePicker.vue';
import ListItemAvailableSlot from '@/components/list-items/ListItemAvailableSlot.vue';
import NoSlots from '@/components/misc/NoSlots.vue';
import PageHeader from '@/components/modal/PageHeader.vue';
import ListItemDivider from '@/components/list-items/ListItemDivider.vue';
import AvailableSlot from '../../models/booking/AvailableSlot';
import store from '../../store/store';

export default Vue.extend({
  components: {
    DatePicker,
    ListItemAvailableSlot,
    ListItemDivider,
    NoSlots,
    PageHeader,
  },
  store,
  computed: {
    ...mapState('calendar', {
      selectedDate: 'selected',
      nextAvailableSlot: 'nextAvailableSlot'
    }),
    ...mapGetters('calendar', {
      datesList: 'getList',
      firstDate: 'first',
      nextDate: 'next',
      canIncreateDate: 'canIncrease',
    }),
    currentDate(): string {
      return this.selectedDate.calendarDate.value;
    },
  },
  mounted() {
    this.initCalendar(this.firstDate);
  },
  methods: {
    ...mapActions('booking', ['reserveSlot']),
    ...mapActions('calendar', ['fetchAvailableSlots']),
    ...mapMutations('calendar', ['setSelectedDate', 'clearState', 'initState']),
    ...mapMutations('overlay', { openOverlay: 'open', closeOverlay: 'close' }),
    ...mapActions('overlay', ['showLoadingSpinner']),

    async initCalendar(date: string): Promise<void> {
      this.initState();
      this.setSelectedDate(date);
      this.listSlots(date, 0);
    },
    async listSlots(date: string, startIndex: number): Promise<void> {
      let currentDate = date;
      for (let i = startIndex; this.datesList.length > i; i += 1) {
        // eslint-disable-next-line
        await this.fetchAvailableSlots(currentDate);
        if (i < this.datesList.length - 1) {
          currentDate = this.nextDate(currentDate);
        }
      }
    },
    async selectSlot(slot: AvailableSlot): Promise<void>  {
      this.$trackingEvents.clickedSelectTimeSlotWidget();
      this.showLoadingSpinner(true);
      const result = await this.reserveSlot(slot);
      this.showLoadingSpinner(false);
      if (result) {
        this.$emit('navigate', { name: 'describe-symptom' });
      } else {
        this.handleSlotsReserved();
      }
    },
    selectDate(date: string): void {
      this.$trackingEvents.selectedDayWidget();
      this.setSelectedDate(date);
      if (this.selectedDate.slotsLoaded) {
        this.fetchAvailableSlots(date);
      }
    },
    handleSlotsReserved(): void {
      this.openOverlay({
        type: 'alert',
        title: this.$t('sorry'),
        description: this.$t('slot_is_already_booked'),
        buttonText: this.$t('i_understand'),
        onClose: (): void => {
          this.closeOverlay();
          this.clearState();
          this.initCalendar(this.firstDate);
        },
      });
    },
  },
})
