import AnimalType from '../models/AnimalType';
import AnimalTypePayload from '../interfaces/animal/AnimalTypePayload';
import AddAnimalsPayload from '../interfaces/animal/AddAnimalsPayload';
import http from '../plugins/http';

export default {

  async fetchAnimalTypes(countryId: number): Promise<Array<AnimalType>> {
    const result = await http.partnerGet('animal-types', { countryId });
    return result.map((type: AnimalTypePayload) => AnimalType.create(type));
  },

  async addAnimals(payload: AddAnimalsPayload): Promise<any> {
    const result = await http.partnerPost('add-animals', payload);
    return result;
  }
};