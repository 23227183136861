/* eslint-disable @typescript-eslint/camelcase */
const ukTranslation: object = {
  next_available_time: 'Next available time',
  next: 'Next',
  with: 'with',
  select_your_pet: 'Select your pet',
  select_pet_description: 'Which pet would you like to speak to a vet about?',
  select_service: 'Select service',
  select_service_description: 'Select a service type below to begin booking your appointment.',
  select_slot_title: 'Pick your time',
  select_slot_description: 'Select a time for your appointment with one of our vets. The meeting will take place in our app.',
  describe_symptom_title: 'Describe your pet\'s condition',
  describe_symptoms_description: 'Please write a short description of your pet\'s symptoms and upload any images that may be helpful for our vet (optional).',
  describe_symptoms_placeholder: 'List any symptoms, conditions, behaviour, etc.',
  continue: 'Continue',
  add_images: 'Add up to 6 images',
  amount_of_images: '{amount} image | {amount} images',
  confirm_phone_number: 'We have sent you an SMS with a {count} digit PIN. Enter it to complete the booking.',
  wrong_pin: 'Wrong PIN',
  please_try_again: 'Please try again',
  go_back_to_update_your_phone_number: 'Go back to update your phone number',
  resend_pin_code: 'Resend code',
  almost_there: 'Almost there',
  confirm_details_description: 'Confirm your info and accept our Terms of Use and Privacy policy. <br/> ' +
    'You must use the exact details that you used to register with your insurer (Full name, email, telephone and date of birth)',
  details_should_match_insurance_info_text: 'You must use the exact details that you used to register with your insurer (Full name, email, telephone and date of birth)',
  continue_to_final: 'Continue to final step',
  phone_number: 'Phone Number',
  time_of_appointment: 'Time of appointment',
  email: 'Email',
  pet: 'Pet',
  terms_of_use: 'Terms and Conditions',
  privacy_policy: 'Privacy Policy',
  confirm_read_understood: 'and confirm that I have read and understood the',
  agree_terms: 'I accept the',
  done_title: 'Success! Now do this.',
  book_another_animal: 'Book for another animal',
  get_sms_reminder: 'You will also get a reminder via SMS.',
  login_with_phone: 'Login with your phone number',
  download_app: 'Download the FirstVet app',
  booking_complete_instruction_1: 'The appointment will take place in the app.',
  booking_complete_instruction_2: 'In the app — login using {phoneNumber}.',
  booking_complete_instruction_3: 'You will find your booking on the home screen.',
  book: 'Book',
  minutes_short: 'min',
  tomorrow: 'Tomorrow',
  today: 'Today',
  no_available_times: 'No more available times this day.',
  free: 'Free',
  jump_next_day: 'Jump to next day',
  images_max_message: 'Max 6 images!',
  image_size_limitation: 'Files are too big, total limit is 10mb!',
  invalid_file_type_message: 'Uploaded image format is not allowed!',
  add_phone_number_title: 'Add your mobile number',
  add_phone_number_description: 'We will send you a message via SMS to confirm your booking and you can use this mobile number to login to the app.',
  phone_placeholder: '705 555 555',
  terms_of_service: 'Terms of service',
  cookie_policy: 'Cookie Policy',
  something_went_wrong: 'Something went wrong',
  contact_our_support: 'Please use the email and phone number linked to the policyholder or download our app and register',
  i_understand: 'I understand',
  slot_is_already_booked: 'Someone else managed to select that time before you. Please select another time slot.',
  slot_no_longer_available: 'Time no longer available',
  slot_no_longer_available_desc: 'Your booking took a little too long so the time you selected is no longer available',
  select_a_new_slot: 'Select a new time',
  sorry: 'Sorry!',
  field_required_message: 'This field is required',
  by_continuing_you_agree: 'By continuing, you agree to our',
  and_confirm_you_have_read: 'and confirm that you have read and understood our',
  including_use_of: 'including use of',
  cookies: 'Cookies',
  tos_text_de_ending: '',
  bad_user_credentials_title: 'We could not create the account',
  bad_user_creation_credentials: 'We are unable to create an account with the phone/email combination.' +
    '<br/>Previously used FirstVet with another number? Try <a href="{loginUrl}">logging in</a> with that number' +
    '<br/>You can also contact <a href="mailto:{supportEmail}">customer service</a>' +
    '<br/>Error code: 1DER',
  support_email: 'support@firstvet.co.uk',
  no_pin_code_received: 'No PIN code received?',
  no_pin_code_download_app: 'If you have not received a PIN code or encountered an error when entering the PIN code, please download the app to book the appointment.' +
  '<br/><br/>In the app, log in with your phone number.' +
  '<br/><br/><a href="{appUrl}" target="_blank" class="download-app">Download the FirstVet app</a>' + 
  '<br/><br/>If you received the PIN code, please close this message to enter the code.',
  app_url: 'https://firstvet.page.link/app-uk',
  close: 'Close',
};

export default ukTranslation;
