






















import Vue from 'vue';
import dayjs from '../../day-js';
import Avatar from '../misc/Avatar.vue';
import Badge from '../misc/Badge.vue';

export default Vue.extend({
  components: {
    Avatar,
    Badge,
  },
  props: {
    availableSlot: {
      required: true,
      type: Object,
    },
  },
  computed: {
    slotTime(): string {
      const { date } = this.availableSlot;
      return date ? dayjs(date).format('LT') : '';
    },
  },
})
