























import Vue from 'vue';
import i18n from './i18n/i18n';
import ListItemNextAvailableSlot from './components/list-items/ListItemNextAvailableSlot.vue';
import FirstvetLogo from './components/misc/FirstvetLogo.vue';
import WidgetRequirements from './interfaces/partner-data/WidgetRequirements';
import ActivitySpinner from './components/spinners/ActivitySpinner.vue';
import config from './config';
import PartnerCustomer from './interfaces/partner-data/PartnerCustomer';
import PartnerAnimal from './interfaces/partner-data/PartnerAnimal';
import NextAvailableSlot from './models/NextAvailableSlot';
import store from './store/store';

export default Vue.extend({
  components: {
    ListItemNextAvailableSlot,
    FirstvetLogo,
    ActivitySpinner,
  },
  i18n,
  store,
  props: {
    country: {
      type: String,
      default: '',
    },
    token: {
      type: String,
      default: '',
    },
    partnerId: {
      type: Number,
      default: 0,
    },
    animals: {
      type: String,
      default: '',
    },
    customer: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      nextAvailableSlot: JSON.parse(localStorage.getItem('fv-next-available-slot')!) || null as NextAvailableSlot | null,
    };
  },
  computed: {
    hasNextAvailableSlot(): boolean {
      return Boolean(this.nextAvailableSlot);
    }
  },
  created() {
    localStorage.setItem('fv-country', this.country);
  },
  mounted() {
    this.initialize();
    this.fetchNextAvailableSlot();
  },
  destroyed() {
    window.FirstvetWidgetAPI.stopNextAvailableSlot();
  },
  methods: {
    initialize(): void {
      const requiredData: WidgetRequirements = {
        country: this.country,
        customer: this.cleanJson(this.customer) as PartnerCustomer,
        animals: this.cleanJson(this.animals) as Array<PartnerAnimal>,
        partnerId: this.partnerId,
        token: this.token,
      };
      window.FirstvetWidgetAPI.initialize(requiredData);
    },
    fetchNextAvailableSlot(): void {
      window.FirstvetWidgetAPI.initNextAvailableSlot(config.COUNTRY_IDS[this.country.toLowerCase()]);
      window.addEventListener('fv-on-fetched-next-available-slot', (event: any) => {
        const nextAvailableSlot = event.detail as NextAvailableSlot;
        localStorage.setItem('fv-next-available-slot', JSON.stringify(nextAvailableSlot));
        this.nextAvailableSlot = nextAvailableSlot;
      });
    },
    openModal(): void {
      window.FirstvetWidgetAPI.openModal();
    },
    cleanJson(json: string): PartnerCustomer | Array<PartnerAnimal> {
      return JSON.parse(json.replace(/'/g, '"'))
    },
  },
});
