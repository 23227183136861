



























































import Vue from 'vue';
import { mapState } from 'vuex';
import Alert from '../overlays/Alert.vue';
import Cookies from '../misc/Cookies.vue';
import FullPage from '../overlays/FullPage.vue';
import PageHeader from './PageHeader.vue';
import LoadingSpinner from '../spinners/LoadingSpinner.vue';

export default Vue.extend({
  components: {
    Alert,
    Cookies,
    FullPage,
    PageHeader,
    LoadingSpinner,
  },
  computed: {
    ...mapState('overlay', ['show', 'type', 'title', 'description', 'buttonText', 'onClose']),
    ...mapState('auth', ['termsOfService', 'privacyPolicy']),
  },
})
