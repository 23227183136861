










import Vue from 'vue';

export default Vue.extend({
  name: 'BookingContainer',
  props: {
    spaceBetween: {
      type: Boolean,
      default: false,
    }
  }
})
