import axios, {AxiosResponse} from 'axios';
import config from '../config';

const axiosInstance = axios.create({
  withCredentials: false,
});

function handleRequest(request: Promise<AxiosResponse<any>>): Promise<any> {
  return request
    .then((res: { data: any }) => res.data)
    .catch((error) => {
      throw error;
    });
}

const getLocalizedURL = () => {
  if (['se'].includes(localStorage.getItem('fv-country') as string)) {
    return process.env.VUE_APP_SV_API_URL
  }
  if (['us'].includes(localStorage.getItem('fv-country') as string)) {
    return process.env.VUE_APP_US_API_URL
  }

  return process.env.VUE_APP_API_URL
}

export default {
  partnerPost: (endpoint: string, body?: object) => {
    const {partner} = JSON.parse(localStorage.getItem(config.VUEX_PERSIST_KEY)!);
    const partnerRequest = axiosInstance.post(`partner/${partner.id}/widget/${endpoint}`, body, {
      baseURL: getLocalizedURL(),
      headers: {
        Authorization: `Bearer ${partner.token}`
      }
    });
    return handleRequest(partnerRequest);
  },

  partnerGet: (endpoint: string, params?: object) => {
    const {partner} = JSON.parse(localStorage.getItem(config.VUEX_PERSIST_KEY)!);
    const partnerRequest = axiosInstance.get(`partner/${partner.id}/widget/${endpoint}`, {
      baseURL: getLocalizedURL(),
      params,
      headers: {
        Authorization: `Bearer ${partner.token}`
      }
    });
    return handleRequest(partnerRequest);
  },

  uploadFile: (endpoint: string, fd: FormData) => {
    const {partner} = JSON.parse(localStorage.getItem(config.VUEX_PERSIST_KEY)!);
    const request = axiosInstance.post(endpoint, fd, {
      baseURL: getLocalizedURL(),
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${partner.token}`
      }
    });
    return handleRequest(request);
  },

  cmsRequest: (endpoint: string) => handleRequest(axiosInstance({
    url: endpoint,
    baseURL: process.env.VUE_APP_CMS_URL
  })),
};