import AnimalTypePayload from '../interfaces/animal/AnimalTypePayload';

export default class AnimalType {

  constructor(
    public id: number,
    public name: string,
    public key: string,
  ) { }

  static create(data: AnimalTypePayload): AnimalType {
    return new this(
      data.id,
      data.name,
      data.key
    );
  }
}