
























import Vue from 'vue';

export default Vue.extend({
  name: 'DatePicker',
  props: {
    dates: {
      type: Array,
      default: () => [{
        title: '' as string,
        value: '' as string,
        disabled: false as boolean,
      }],
    },
    value: {
      type: String,
      default: null,
    },
  },
})
