





































import Vue from 'vue';

export default Vue.extend({
  name: 'ListItemNavigation',
  props: {
    image: {
      type: String,
      default: '',
    },
    imageSize: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: '',
      required: true,
    },
    secondaryTitle: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    chevron: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      imageSizes: {
        0: 24,
        1: 40,
        2: 56,
      } as Record<number, number>,
    };
  },
  computed: {
    imageWidth(): number {
      return this.imageSizes[this.imageSize];
    }
  },
})
