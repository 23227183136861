import PrivacyPolicyResponse from '../interfaces/auth/responses/PrivacyPolicyResponse';

export default class PrivacyPolicy {

  constructor(
    public isLoading: boolean,
    public title: string,
    public html: string,
  ) { }

  static formatHttpResponse(response: PrivacyPolicyResponse) {
    return new this(
      false,
      response.text,
      response.content,
    );
  }
}
