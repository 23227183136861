import CalendarListItem from '../../../../models/calendar/CalendarListItem';
import AvailableSlot from '../../../../models/booking/AvailableSlot';

export default class CalendarState {

  constructor(
    public dates: Array<CalendarListItem>,
    public selected: CalendarListItem | null,
    public nextAvailableSlot: AvailableSlot | null,
    public cleared: boolean,
  ) { }

  static create(amountOfDays: number, cleared = false): CalendarState {
    return new this(
      [...Array(amountOfDays).keys()].map((index) => CalendarListItem.create(index)),
      null,
      null,
      cleared,
    );
  }

  static findIndexByDate (dates: Array<CalendarListItem>, date: string): number {
    return dates.map((item: CalendarListItem) => item.calendarDate.value).indexOf(date);
  }
}
