


















import Vue from 'vue';
import PageHeader from '@/components/modal/PageHeader.vue';
import ListItemAction from '../list-items/ListItemAction.vue';

export default Vue.extend({
  components: {
    PageHeader,
    ListItemAction,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: '',
    },
  },
})
