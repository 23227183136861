













import Vue from 'vue';

export default Vue.extend({
  name: 'ScrollContainer',
  data() {
    return {
      showShadow: false,
    };
  },
  mounted() {
    this.listenOnScroll();
  },
  methods: {
    listenOnScroll(): void {
      const container = document.getElementById('scroll-container');
      if (container) {
        container.addEventListener('scroll', (): void => {
          this.showShadow = container.scrollTop > 0;
        });
      }
    }
  },
})
