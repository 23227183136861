import firebase from 'firebase';
import config from '../config';

export default class Firebase {

  private readonly SHOULD_INITIALIZE: boolean = process.env.NODE_ENV === 'production';

  public setup = (): firebase.analytics.Analytics | null => {
    if (this.SHOULD_INITIALIZE) {
      const { FIREBASE } = config;
      const firebaseApp = firebase.apps.length ? firebase.app() : firebase.initializeApp({
        apiKey: FIREBASE.API_KEY,
        authDomain: FIREBASE.AUTH_DOMAIN,
        projectId: FIREBASE.PROJECT_ID,
        storageBucket: FIREBASE.STORAGE_BUCKET,
        messagingSenderId: FIREBASE.MESSAGING_SENDER_ID,
        appId: FIREBASE.APP_ID,
        measurementId: FIREBASE.MEASUREMENT_ID,
      });
      return firebase.analytics(firebaseApp);
    }
    return null;
  }

  public fireEvent = (eventName: string, eventData?: object): void => {
    if (this.isInitialized()) {
      firebase.analytics().logEvent(eventName, eventData);
    }
  }

  public setUserProperties = (data: {
    market?: string;
    partnerId?: number;
  }): void => {
    if (this.isInitialized()) {
      firebase.analytics().setUserProperties(data);
    }
  }

  private isInitialized = (): boolean => {
    return firebase.apps.length > 0;
  }
}