const DEFAULT_DATA = {
  country: 'uk',
  token: 'abc_dummy',
  partnerId: 100,
  customer: JSON.stringify({
    firstName: 'Test',
    lastName: 'Testson',
    email: 'test@tester.com',
    mobilePhone: '123456789'
  }),
  animals: JSON.stringify([{
    uid: '123123122',
    name: 'Bosse',
    type: 'dog',
    birthDate: '2021-04-10',
    policyNumber: '12312313132223-2',
  }, {
    uid: '123123123',
    name: 'Billie',
    type: 'cat',
    birthDate: '2021-02-10',
    policyNumber: '12312313132223-2',
  }]),
};

export default DEFAULT_DATA;