














import Vue from 'vue';

export default Vue.extend({
  name: 'ListItemPinInput',
  props: {
    value: {
      type: String,
      default: '',
    }
  },
})
