









import Vue from 'vue';

export default Vue.extend({
  name: 'FvIcon',
  props: {
    icon: {
      type: String,
      required: true,
    },
  },
  computed: {
    fileName() {
      return this.icon
        .split('-')
        .map(string => string.charAt(0).toUpperCase() + string.slice(1))
        .join('-');
    },
  },
});
