import dayjs from '../../../../day-js';
import config from '../../../../config';
import CalendarListItem from '../../../../models/calendar/CalendarListItem';
import bookingService from '../../../../services/booking.service';
import AvailableSlot from '../../../../models/booking/AvailableSlot';
import CalendarDate from '../../../../models/calendar/CalendarDate';
import CalendarState from './calendar.state';

export default {

  state: CalendarState.create(config.AMOUNT_OF_CALENDAR_DATES),

  getters: {
    getList: (
      state: CalendarState,
    ): Array<CalendarListItem> => {
      return state.dates.map((date: any) => date.calendarDate);
    },

    filterSlots: (
      state: CalendarState,
    ) => (availableSlots: Array<AvailableSlot>): Array<AvailableSlot> => {
      return availableSlots.filter((slot) => {
        return slot.dateInfo.time !== (state.nextAvailableSlot && (state.nextAvailableSlot.dateInfo.time));
      });
    },

    findIndexByDate: (
      state: CalendarState,
    ) => (date: string): number => {
      return state.dates.map((item: CalendarListItem) => item.calendarDate.value).indexOf(date);
    },

    first: (
      state: CalendarState,
    ): string => {
      const [ first ] = state.dates;
      return first.calendarDate.value;
    },

    next: (
      state: CalendarState,
    ) => (date: string): string => {
      const index = CalendarState.findIndexByDate(state.dates, date);
      return state.dates[index + 1].calendarDate.value;
    },

    canIncrease: (
      state: CalendarState,
    ) => (date: string): boolean => {
      const index = CalendarState.findIndexByDate(state.dates, date);
      return index < (state.dates.length - 1);
    },
  },

  mutations: {
    setNextAvailableSlot: (
      state: CalendarState,
      slot: AvailableSlot,
    ) => {
      state.nextAvailableSlot = slot;
    },

    setAvailableSlots: (
      state: CalendarState,
      payload: {
        slots: Array<AvailableSlot>;
        date: string;
      },
    ) => {
      const index = CalendarState.findIndexByDate(state.dates, payload.date);

      state.dates[index] = {
        ...state.dates[index],
        slots: payload.slots,
        slotsLoaded: true,
      };

      const shouldUpdateSelectedDate = state.selected
        && (state.dates[index].calendarDate.value === state.selected.calendarDate.value);

      if (shouldUpdateSelectedDate) {
        state.selected = state.dates[index];
      }
    },

    setSelectedDate: (
      state: CalendarState,
      date: any,
    ) => {
      const index = CalendarState.findIndexByDate(state.dates, date);
      state.selected = state.dates[index];
    },

    clearState: (
      state: CalendarState,
    ) => {
      Object.assign(state, { ...CalendarState.create(config.AMOUNT_OF_CALENDAR_DATES, true) })
    },

    initState: (
      state: CalendarState,
    ) => {
      Object.assign(state, { ...CalendarState.create(config.AMOUNT_OF_CALENDAR_DATES) })
    }
  },

  actions: {
    fetchAvailableSlots({ commit, getters, rootState, state }: any, date: string) {
      return bookingService.fetchAvailableSlots({
        fromDate: date,
        toDate: date,
        fromTime: CalendarDate.isToday(date) ? dayjs().format('HH:mm:ss') : '',
        countryId: rootState.client.countryId,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        animalTypeId: rootState.animal.selected.type.id,
        serviceId: rootState.service.selected.id || config.VET_SERVICE_ID,
        random: 1,
        withPrice: config.SHOW_PRICE,
      })
        .then((availableSlots: Array<AvailableSlot>): boolean => {
          if (availableSlots.length > 0 && !state.cleared && !state.nextAvailableSlot) {
            const [ nextAvailableSlot ] = availableSlots;
            commit('setNextAvailableSlot', nextAvailableSlot);
          }
          commit('setAvailableSlots', {
            slots: getters.filterSlots(availableSlots),
            date,
          });

          return true;
        }).catch(console.error);
    }
  }
}
