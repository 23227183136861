export default class ServiceInfo {

  constructor(
    public id: number,
    public name: string,
  ) { }

  static formatHttpResponse(response: any): ServiceInfo {
    return new this(
      response.id,
      response.name,
    );
  }
}
