

















import Vue from 'vue';

export default Vue.extend({
  name: 'FullPage',
  props: {
    show: {
      type: Boolean,
      default: false,
    }
  }
})
