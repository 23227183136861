


















import Vue from 'vue'
import ListItemAction from '../list-items/ListItemAction.vue';

export default Vue.extend({
  components: {
    ListItemAction,
  },
  props: {
    showButton: {
      type: Boolean,
      default: true,
    },
    fill: {
      type: Boolean,
      default: false,
    }
  }
})
