import store from '../../store';
import ClientState from './client.state';

const COUNTRY_ID_SWEDEN = 752;

export default {
  state: {
    isInitializing: false,
    countryId: null,
    device: 'desktop', // TODO: Set desktop/mobile.
  } as ClientState,

  getters: {
    getIsSwedish: (
      state: ClientState
    ) => {
      return state.countryId === COUNTRY_ID_SWEDEN
    }
  },

  mutations: {
    setCountryId: (
      state: ClientState,
      countryId: number
    ) => {
      state.countryId = countryId
    },

    setIsInitializing: (
      state: ClientState,
      isInitializing: boolean,
    ) => {
      store.dispatch('overlay/showLoadingSpinner', isInitializing);
      state.isInitializing = isInitializing;
    },
  },
}